<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey"  @keyup.enter.native="handleFilter" style="width: 200px;"  placeholder="商品名称、优惠券名称"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item">
				<label class="label">兑换类型: </label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 优惠券状态 -->
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addRedeem'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleTableEdit">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column label="商品" width="350px">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img v-if="scope.row.Type==1" style="width:80px;height:80px;object-fit:contain;margin-right: 10px;border:1px solid #ddd;"
							 src='https://api.static.huibogoulive.cn/images/melyshop/pc-integralMall-coupon.png' />
							<img v-else style="width:80px;height:80px;object-fit:contain;margin-right: 10px;border:1px solid #ddd;" :src='imgUrl + scope.row.ImgUrl' />
							<div class="right">
								<div class="name">
									<span style="color:#F56C6C" v-if="scope.row.ProductType==1">[组合]</span>

									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

								</div>
								<div v-if="scope.row.Type!=1">
									<span>原价：</span>
									<span style="color:#F56C6C">{{scope.row.ProductPrice}}</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ConversionPrice" label="兑换价"></el-table-column>
				<el-table-column prop="ExchangedCount" label="已兑换/剩余">
					<template slot-scope="scope">
						{{scope.row.ExchangedCount}}/{{scope.row.RemainingCount}}
					</template>
				</el-table-column>
				<el-table-column prop="Limit" label="限兑数量"></el-table-column>
				<el-table-column prop="" label="兑换时间">
					<template slot-scope="scope">
						{{scope.row.StartTime}}/{{scope.row.EndTime}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="状态">
					<template slot-scope="scope">
						<span>{{scope.row.ActivityStateValue}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div v-if="scope.row.ActivityState== 2">
							<div class="table-button" @click="handleTableEdit(scope.row)">查看</div>

							<button-permissions :datas="'copyRedeem'">
								<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
							</button-permissions>

							<button-permissions :datas="'deleteRedeem'">
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
							</button-permissions>
						</div>
						<div v-else>
							<button-permissions :datas="'editRedeem'">
								<div class="table-button" @click="handleTableEdit(scope.row)">编辑</div>
							</button-permissions>
							
							<button-permissions :datas="'cancelRedeem'">
								<div class="table-button" @click="handleTableFail(scope.row.Id)">使终止</div>
							</button-permissions>

							<button-permissions :datas="'copyRedeem'">
								<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
							</button-permissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="pageIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import apiList from '@/api/other.js';

	import config from '@/config/index.js';

	import buttonPermissions from '@/components/buttonPermissions';

	export default{
		components: {
			buttonPermissions
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				searchKey: '',
				type: null,
				typeOptions: [{
						value: 0,
						label: '商品'
					},
					{
						value: 1,
						label: '优惠券'
					}
				],
				editDialog: false,
				// editRadio: 1,
				status: null,
				statusOptions: [{
						value: 0,
						label: '未开始'
					},
					{
						value: 1,
						label: '进行中'
					},
					{
						value: 2,
						label: '已结束'
					}
				],
				tableData: [],
				pageIndex: 1,
				pageSize: 20,
				total: 0
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWord: this.searchKey, // 搜索关键字
						ActivityState: this.status,
						Type: this.type,
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize
					}
					let result = await apiList.activityPointExchangeList(data);

					this.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			handleFilter() {
				this.getList()
			},
			// table查看
			handleTableView(id) {
				this.$router.push({
					path: '/Customer/integralMall/editIntegralExchange',
					query: {
						id: val.Id,
						ActivityState: 3
					}
				})
			},
			//复制
			handleTableCopy(val) {
				this.$router.push({
					path: '/Customer/integralMall/editIntegralExchange',
					query: {
						copyId: val.Id,
						ActivityState: 3
					}
				})
			},
			// 编辑，添加
			handleTableEdit(record) {
				//ActivityState:0：未开始； 1：进行中 ； 2：已结束，所有的都不能编辑 ；3:表示所有的都可以编辑。
				this.$router.push({
					path: '/Customer/integralMall/editIntegralExchange',
					query: {
						id: record ? record.Id : null,
						ActivityState: record.Id ? record.ActivityState : 3
					}
				})
			},
			// table使终止
			handleTableFail(id) {
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？', '提示', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let result = await apiList.activityPointExchangeOpen({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '活动已终止!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消终止'
					});
				}).finally(() => {})
			},
			// table删除
			handleTableDelete(id) {

				this.$confirm('是否确认删除活动？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let result = await apiList.activityPointExchangeDelete({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '删除成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					// this.soetData();
					// this.getSort();
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;

		.filter-item {
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		/deep/.el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		// img {
		// 	width: 80px;
		// 	height: 80px;
		// 	object-fit: contain;

		// }

		.name {
			width: 240px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}
</style>
